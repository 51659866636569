/**
 * Applies content blocks to a content.
 *
 * This class has a corresponding file in the backend as well: \App\Services\ContentBlocks\ContentBlockApplier
 * Important: Keep them in sync!
 */
export class ContentBlockApplier {

    public static applyBlockForHtml(content: string, contentBlockKey: string, replacement: string): string {
        const searchValue = `<!--start-data-block='${contentBlockKey}'-->(.*?)<!--end-data-block='${contentBlockKey}'-->`;
        const replaceValue = `<!--start-data-block='${contentBlockKey}'-->${replacement}<!--end-data-block='${contentBlockKey}'-->`;
        return content.replace(new RegExp(searchValue, 'gms'), replaceValue);
    }

}
