/**
 * Trims a value. If the value is undefined, trimmed value is empty, undefined will be returned.
 * @param value The value to trim
 */
export function trimOrUndefined(value?: string): string | undefined {
    if (value != null) {
        const trimmedValue = value.trim();
        if (trimmedValue.length === 0) {
            return undefined;
        } else {
            return trimmedValue;
        }
    } else {
        return undefined;
    }
}
