import {ArchitectElementAction, ArchitectElementActionCreator} from '../architect-element';
import {InlineTextEditor} from '../../overlays/inline-text-editor/inline-text-editor.service';
import {ArchitectTextEl} from '../architect-text-element';

export const textEditActionCreator: ArchitectElementActionCreator = (archEl: ArchitectTextEl): ArchitectElementAction => ({
    id: 'action.text.edit',
    name: 'Edit Text',
    onClick: (node: HTMLElement) => {
        archEl.inject(InlineTextEditor).open(node);
    },
});
