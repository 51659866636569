<div>
    <div class="modal-header">
        <h2 class="modal-title" mat-dialog-title trans>Contact Person</h2>
        <button type="button" (click)="close()" class="close-button no-style" tabindex="-1">
            <mat-icon svgIcon="close"></mat-icon>
        </button>
    </div>
    <loading-bar *ngIf="_processing"></loading-bar>
</div>

<mat-dialog-content>
    <div class="col col-grow many-inputs">
        <div class="row">
            <div class="col">
                <div class="input-container">
                    <label for="title" trans>Name</label>
                    <input type="text" id="name" name="name" [(ngModel)]="data.widget.name" [disabled]="_processing">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="input-container">
                    <label for="title" trans>Title</label>
                    <textarea id="title" name="title" [(ngModel)]="data.widget.title"
                              rows="4" cols="40"
                              [disabled]="_processing"></textarea>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <label trans>Image</label>
                <div class="image-preview"
                     *ngIf="getPrimaryImageSrc(data.widget)"
                     (click)="!_processing && updatePrimaryImage(data.widget)"
                     [matTooltip]="'Change image' | trans">
                    <img [src]="getPrimaryImageSrc(data.widget)" [alt]="data.widget.imageAlt">
                </div>
                <div class="action-buttons">
                    <button type="button" class="action-button" mat-stroked-button color="accent"
                            (click)="!_processing && updatePrimaryImage(data.widget)" trans>Select
                    </button>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <label trans>Hover image</label>
                <div class="image-preview"
                     *ngIf="getHoverImageSrc(data.widget)"
                     (click)="!_processing && updatePrimaryImage(data.widget)"
                     [matTooltip]="'Change image' | trans">
                    <img [src]="getHoverImageSrc(data.widget)" [alt]="data.widget.imageAlt">
                </div>
                <div class="action-buttons">
                    <button type="button" class="action-button" mat-stroked-button color="accent"
                            (click)="!_processing && updateHoverImage(data.widget)" trans>Select
                    </button>
                    <button type="button" class="action-button delete-button" mat-icon-button
                            [matTooltip]="'Remove background image' | trans"
                            *ngIf="getHoverImageSrc(data.widget)"
                            (click)="!_processing && deleteHoverImage(data.widget)">
                        <mat-icon svgIcon="delete"></mat-icon>
                    </button>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="input-container">
                <label for="imageAlt" trans>Alt label</label>
                <input type="text" id="imageAlt" name="imageAlt" [(ngModel)]="data.widget.imageAlt"
                       [disabled]="_processing">
            </div>
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions>
    <button (click)="close()" type="button" mat-button trans>Cancel</button>
    <button (click)="submit()" mat-raised-button color="accent" [disabled]="_processing" trans>Apply</button>
</mat-dialog-actions>
