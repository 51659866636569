import {ArchitectElement, ArchitectElementAction} from './architect-element';
import {CurrentUser} from '@common/auth/current-user';
import {Injector} from '@angular/core';
import {I18nKeyControl} from './definitions/i18n-widget/i18n-key-control';
import {i18nEditActionCreator} from './definitions/i18n-widget/i18n-edit-action';
import {textEditActionCreator} from './actions/text-edit-action';
import {EditableProp} from './architect-element-editable-props';

export abstract class ArchitectTextEl extends ArchitectElement {
    canEdit: EditableProp[] = [
        EditableProp.Padding,
        EditableProp.Margin,
        EditableProp.Border,
        EditableProp.Attributes,
        EditableProp.Shadow,
        EditableProp.Background,
        EditableProp.Text,
    ];
    editActions: ArchitectElementAction[] = [
        textEditActionCreator(this),
    ];

    constructor(protected injector: Injector) {
        super(injector);

        const currentUser = injector.get(CurrentUser);

        if (currentUser.hasPermission('templates.update_i18n')) {
            this.controls.push(new I18nKeyControl(this.injector));
            this.editActions.push(i18nEditActionCreator(this));
        }
    }
}
