import {Component, Inject, OnInit, Optional} from '@angular/core';
import {BuilderStateService} from '../../../../builder-state.service';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {ImageElementService} from '../../../image-element.service';
import {ProjectUrl} from '../../../../../shared/projects/project-url.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {CardCarouselWidget, CardCarouselWidgetCard} from '../card-carousel-widget.model';
import {getEmbeddedVideoUrl} from '../../../../../shared/video/videos.util';
import {CkEditor5} from '@common/text-editor/ckeditor5/CkEditor5';
import * as ckEditorUtils from '@common/text-editor/ckeditor5/ckeditor5.util';
import {trimOrUndefined} from '@common/core/utils/trim-or-undefined';

export interface CardCarouselWidgetEditorModalComponentData {
    widget: CardCarouselWidget;
}

@Component({
    selector: 'card-carousel-widget-editor-modal',
    templateUrl: './card-carousel-widget-editor-modal.component.html',
    styleUrls: ['./card-carousel-widget-editor-modal.component.scss'],
})
export class CardCarouselWidgetEditorModalComponent implements OnInit {

    loading = false;
    _processing = false;
    advancedOptionsVisible = false;

    constructor(
        public ckEditor5: CkEditor5,
        private projectUrl: ProjectUrl,
        public state: BuilderStateService,
        public imageElementService: ImageElementService,
        @Inject(MAT_DIALOG_DATA)
        @Optional()
        public data: CardCarouselWidgetEditorModalComponentData,
        @Inject(MatDialogRef) @Optional() public modalRef: MatDialogRef<CardCarouselWidgetEditorModalComponent>
    ) {
    }

    ngOnInit() {
        this.prepareData();
    }

    private prepareData() {
        this.data.widget.cards.forEach(card => {
            if (!card.text.startsWith('<p>')) {
                card.text = `<p>${card.text}</p>`;
            }
        });
    }

    submit() {
        this.cleanupData();
        this.close(this.data.widget);
    }

    close(value?: CardCarouselWidget) {
        this.cleanupData();
        this.modalRef.close(value);
    }

    private cleanupData(): void {
        this.data.widget.cards.forEach(card => {
            card.text = trimOrUndefined(ckEditorUtils.cleanupParagraphs(card.text));
        });
    }

    drop(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.data.widget.cards, event.previousIndex, event.currentIndex);
    }

    addItem() {
        const newItem = new CardCarouselWidgetCard();
        this.data.widget.cards.push(newItem);
    }

    removeItem(cardIndex: number) {
        this.data.widget.cards.splice(cardIndex, 1);
    }

    canMoveUp(cardIndex: number) {
        return cardIndex > 0;
    }

    moveUp(cardIndex: number) {
        const slide = this.data.widget.cards[cardIndex];
        this.data.widget.cards[cardIndex] = this.data.widget.cards[cardIndex - 1];
        this.data.widget.cards[cardIndex - 1] = slide;
    }

    canMoveDown(cardIndex: number) {
        return cardIndex < this.data.widget.cards.length - 1;
    }

    moveDown(cardIndex: number) {
        const slide = this.data.widget.cards[cardIndex];
        this.data.widget.cards[cardIndex] = this.data.widget.cards[cardIndex + 1];
        this.data.widget.cards[cardIndex + 1] = slide;
    }

    getImageSrc(card: CardCarouselWidgetCard): string | undefined {
        if (card.imageSrc == null || card.imageSrc.length === 0) {
            return undefined;
        }
        return `${this.projectUrl.getBaseUrl(this.state.project$.value.model)}/${card.imageSrc}`;
    }

    updateImage(card: CardCarouselWidgetCard) {
        this._processing = true;
        this.imageElementService.openModal().subscribe((url: string) => {
            this._processing = false;
            if (!url) return;
            card.imageSrc = url;
        });
    }

    deleteImage(card: CardCarouselWidgetCard) {
        card.imageSrc = undefined;
        card.imageAlt = undefined;
    }

    videoUrlChanged(card: CardCarouselWidgetCard) {
        card.videoSrc = getEmbeddedVideoUrl(card.videoUrl);
    }

}
