import {ArchitectElement} from '../../architect-element';
import {MutationsService} from '../../../mutations/mutations.service';
import {ReplaceNodeContent} from '../../../mutations/dom/replace-node-content';
import {extractCardCarouselWidgetCard} from './contact-persons-widget-markup-extractor';
import {generateCardCarouselWidgetCardMarkup} from './contact-persons-widget-markup-generator';
import {ContactPersonsModel} from './contact-persons-widget.model';
import {ContactPersonsWidgetEditor} from './contact-persons-widget-editor.service';
import {DeleteNode} from '../../../mutations/dom/delete-node';
import {InsertNode} from '../../../mutations/dom/insert-node';

export const template = `<div class="contact-person text-center mb-0 p-lg-5 p-md-2 p-sm-1">
    <img src="images/placeholder/300x300.png" alt="Merlin Motiviert, Maskottchen"
         class="img-circle img-fluid"
         data-primary="images/placeholder/300x300.png"
         data-switch="images/placeholder/300x300.png">
    <p class="mt-3" style="word-break: break-all"><strong>Merlin Motiviert</strong><br>
    Maskottchen<br>
    Moralverantwortlich<br></p>
</div>`;

export class ContactPersonsWidgetEl extends ArchitectElement {
    name = 'Contact Person';
    contentCategories = ['contact-person'];
    allowedContent = [];
    blocksContent = true;
    category = null;
    icon = 'card-carousel';
    specificity = 6;
    hiddenClasses = ['contact-person'];
    editActions = [
        {
            preventActionIds: ['action.text.edit'],
            name: 'Edit',
            onClick: (node: HTMLElement) => {
                const widget = extractCardCarouselWidgetCard(node);
                this.inject(ContactPersonsWidgetEditor)
                    .open(node, widget)
                    .afterClosed()
                    .subscribe((updatedWidget?: ContactPersonsModel) => {
                        if (updatedWidget != null) {
                            const newInnerHTML = generateCardCarouselWidgetCardMarkup(updatedWidget);
                            this.inject(MutationsService).execute(
                                new ReplaceNodeContent(node, node.innerHTML, newInnerHTML)
                            );
                        }
                    });
            },
        },
        {
            preventActionIds: ['action.text.edit'],
            name: 'Delete',
            onClick: (node: HTMLElement) => {
                const parentNode = node.parentElement;

                this.inject(MutationsService).execute(
                    new DeleteNode(node)
                );

                if (parentNode.childElementCount === 0) {
                    this.inject(MutationsService).execute(
                        new DeleteNode(parentNode)
                    );
                }
            },
        },
        {
            name: 'Add Person before',
            onClick: (node: HTMLElement) => {
                const newElement = this.buildTemplate();

                this.inject(MutationsService).execute(
                    new InsertNode(newElement, this.getIndexOfElement(node) ?? 0, node.parentElement.dataset.arId)
                );
            }
        }, {
            name: 'Add Person behind',
            onClick: (node: HTMLElement) => {
                const newElement = this.buildTemplate();

                this.inject(MutationsService).execute(
                    new InsertNode(newElement, (this.getIndexOfElement(node) ?? 0) + 1, node.parentElement.dataset.arId)
                );
            }
        }
    ];

    matcher(node: HTMLElement) {
        return node.closest('.contact-person') as HTMLElement;
    }

    private buildTemplate(): HTMLElement {
        return new DOMParser()
            .parseFromString(template, 'text/html')
            .querySelector('.contact-person') as HTMLElement;
    }

    private getIndexOfElement(element: HTMLElement): number | undefined {
        const childElements = element.parentElement.querySelectorAll('div.contact-person');

        for (let i = 0; i < childElements.length; i++) {
            if (childElements[i] === element) {
                return i;
            }
        }

        return undefined;
    }
}
