import {Component, Inject, Optional} from '@angular/core';
import {BuilderStateService} from '../../../../builder-state.service';
import {ImageElementService} from '../../../image-element.service';
import {ProjectUrl} from '../../../../../shared/projects/project-url.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ContactPersonsModel} from '../contact-persons-widget.model';

export interface ContactPersonWidgetEditorModalComponentData {
    widget: ContactPersonsModel;
}

@Component({
    selector: 'contact-persons-widget-editor-modal',
    templateUrl: './contact-persons-widget-editor-modal.component.html',
    styleUrls: ['./contact-persons-widget-editor-modal.component.scss'],
})
export class ContactPersonsWidgetEditorModalComponent {

    _processing = false;

    constructor(
        private projectUrl: ProjectUrl,
        public state: BuilderStateService,
        public imageElementService: ImageElementService,
        @Inject(MAT_DIALOG_DATA)
        @Optional()
        public data: ContactPersonWidgetEditorModalComponentData,
        @Inject(MatDialogRef)
        @Optional()
        public modalRef: MatDialogRef<ContactPersonsWidgetEditorModalComponent>
    ) {
    }

    submit() {
        this.close(this.data.widget);
    }

    close(value?: ContactPersonsModel) {
        this.modalRef.close(value);
    }

    getPrimaryImageSrc(card: ContactPersonsModel): string | undefined {
        if (card.primaryImageSrc == null || card.primaryImageSrc.length === 0) {
            return undefined;
        }
        return `${this.projectUrl.getBaseUrl(this.state.project$.value.model)}/${card.primaryImageSrc}`;
    }

    updatePrimaryImage(card: ContactPersonsModel) {
        this._processing = true;
        this.imageElementService.openModal().subscribe((url: string) => {
            this._processing = false;
            if (!url) return;
            card.primaryImageSrc = url;
        });
    }

    getHoverImageSrc(card: ContactPersonsModel): string | undefined {
        if (card.hoverImageSrc == null || card.hoverImageSrc.length === 0) {
            return undefined;
        }
        return `${this.projectUrl.getBaseUrl(this.state.project$.value.model)}/${card.hoverImageSrc}`;
    }

    updateHoverImage(card: ContactPersonsModel) {
        this._processing = true;
        this.imageElementService.openModal().subscribe((url: string) => {
            this._processing = false;
            if (!url) return;
            card.hoverImageSrc = url;
        });
    }

    deleteHoverImage(card: ContactPersonsModel) {
        card.hoverImageSrc = undefined;
    }
}
