import {ArchitectElControl, ElControlType} from '../../architect-element';
import {Injector} from '@angular/core';
import {MutationsService} from '../../../mutations/mutations.service';
import {ModifyAttributes} from '../../../mutations/attributes/modify-attributes';

export class I18nKeyControl extends ArchitectElControl {

    constructor(injector: Injector) {
        super(injector, {
            label: 'i18n key',
            type: ElControlType.Input,
            inputType: 'text',
            defaultValue(node: HTMLInputElement) {
                return node.getAttribute('data-esb-i18n') ?? '';
            },
            onChange(node: HTMLElement, value: string) {
                this.inject(MutationsService).execute(
                    new ModifyAttributes(node, {
                        'data-esb-i18n': value !== '' ? value : undefined,
                    })
                );
            },
            isEnabled(node: HTMLElement) {
                // only enabled, if it is not nested, i.e. no parent or child has this attribute

                const closestNode = node.closest('[data-esb-i18n]');
                if (closestNode != null && closestNode !== node) {
                    // parent node has the i18n attribute
                    return false;
                }

                const childNode = node.querySelector('[data-esb-i18n]');
                if (childNode != null) {
                    // a child node has the i18n attribute
                    return false;
                }

                return true;
            }
        });
    }

}
