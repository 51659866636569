import {Injectable} from '@angular/core';
import {
    ContactPersonsWidgetEditorModalComponent,
    ContactPersonWidgetEditorModalComponentData
} from './contact-persons-widget-editor-modal/contact-persons-widget-editor-modal.component';
import {MatDialogRef} from '@angular/material/dialog/dialog-ref';
import {Modal} from '@common/core/ui/dialogs/modal.service';
import {ContactPersonsModel} from './contact-persons-widget.model';

@Injectable({
    providedIn: 'root',
})
export class ContactPersonsWidgetEditor {
    public modalRef: MatDialogRef<ContactPersonsWidgetEditorModalComponent>;

    constructor(
        public modal: Modal
    ) {
    }

    open(
        origin: HTMLElement,
        widget: ContactPersonsModel
    ): MatDialogRef<ContactPersonsWidgetEditorModalComponent> {
        const data: ContactPersonWidgetEditorModalComponentData = {
            widget,
        };
        this.modalRef = this.modal.open(ContactPersonsWidgetEditorModalComponent, data);
        return this.modalRef;
    }

    close() {
        this.modalRef?.close();
    }
}
