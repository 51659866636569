<div>
    <div class="modal-header">
        <h2 class="modal-title" mat-dialog-title trans>Block Grid</h2>
        <button type="button" (click)="close()" class="close-button no-style" tabindex="-1">
            <mat-icon svgIcon="close"></mat-icon>
        </button>
    </div>
    <loading-bar *ngIf="loading"></loading-bar>
</div>

<mat-dialog-content>
    <div cdkDropList class="list" (cdkDropListDropped)="drop($event)">
        <div class="list-item" *ngFor="let item of data.widget.items; let i = index" cdkDrag [cdkDragDisabled]="loading">
            <button cdkDragHandle mat-icon-button class="drag-handle">
                <mat-icon svgIcon="drag-handle" matListIcon></mat-icon>
            </button>

            <div class="col col-grow many-inputs">
                <div class="row" style="align-items: center;">
                    <div class="col col-grow">
                        <mat-slide-toggle [(ngModel)]="item.enabled" trans>
                            Enabled
                        </mat-slide-toggle>
                    </div>
                    <div class="col col-grow slide-actions">
                        <div class="row" style="justify-content: end;">
                            <button type="button" mat-icon-button *ngIf="canMoveUp(i)"
                                    (click)="moveUp(i)" [matTooltip]="'Move up' | trans" [disabled]="_processing">
                                <mat-icon svgIcon="move-up"></mat-icon>
                            </button>
                            <button type="button" mat-icon-button *ngIf="canMoveDown(i)"
                                    (click)="moveDown(i)" [matTooltip]="'Move down' | trans" [disabled]="_processing">
                                <mat-icon svgIcon="move-down"></mat-icon>
                            </button>
                            <button type="button" mat-icon-button
                                    (click)="removeItem(i)" [matTooltip]="'Remove' | trans" [disabled]="_processing">
                                <mat-icon svgIcon="delete"></mat-icon>
                            </button>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col col-grow many-inputs">
                        <div class="row">
                            <div class="col col-grow">
                                <div class="input-container">
                                    <label for="layout" trans>Layout</label>
                                    <ng-select id="layout" name="layout"
                                               appearance="outline"
                                               appendTo="body"
                                               [clearable]="false"
                                               [(ngModel)]="item.layout"
                                               [ngModelOptions]="{standalone: true}">
                                        <ng-option [value]="'x1_y1'">
                                            <div class="flex items-center">
                                                <div class="block-grid-layout-table-icon">
                                                    <table>
                                                        <tr>
                                                            <td></td>
                                                        </tr>
                                                    </table>
                                                </div>
                                                <span trans>1x1</span>
                                            </div>
                                        </ng-option>
                                        <ng-option [value]="'x2_y1'">
                                            <div class="flex items-center">
                                                <div class="block-grid-layout-table-icon">
                                                    <table>
                                                        <tr>
                                                            <td></td>
                                                            <td></td>
                                                        </tr>
                                                    </table>
                                                </div>
                                                <span trans>2x1 (horizontal)</span>
                                            </div>
                                        </ng-option>
                                        <ng-option [value]="'x1_y2'">
                                            <div class="flex items-center">
                                                <div class="block-grid-layout-table-icon">
                                                    <table>
                                                        <tr>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td></td>
                                                        </tr>
                                                    </table>
                                                </div>
                                                <span trans>1x2 (vertical)</span>
                                            </div>
                                        </ng-option>
                                    </ng-select>
                                </div>
                            </div>
                            <div class="col col-grow"></div>
                        </div>

                        <div class="input-container">
                            <label for="title" trans>Title</label>
                            <textarea type="text" id="title" name="title" [(ngModel)]="item.title" [disabled]="_processing"></textarea>
                        </div>
                        <div class="input-container">
                            <label for="description" trans>Description</label>
                            <textarea type="text" id="description" name="description" [(ngModel)]="item.description" [disabled]="_processing"></textarea>
                        </div>
                        <div class="input-container mb-2">
                            <label for="href" trans>Url</label>
                            <input type="text" id="href" name="href" [(ngModel)]="item.href" [disabled]="_processing">
                        </div>
                        <div class="input-container">
                            <mat-checkbox [(ngModel)]="item.hrefNewTab" [disabled]="_processing">
                                <span trans>Open in new tab</span>
                            </mat-checkbox>
                        </div>

                        <div class="row">
                            <div class="col">
                                <div class="input-container">
                                    <label for="backgroundColor" trans>Background color</label>
                                    <color-input id="backgroundColor" [(ngModel)]="item.backgroundColor" [disabled]="_processing"></color-input>
                                </div>
                            </div>
                            <div class="col">
                                <div class="input-container">
                                    <label for="textColor" trans>Text color</label>
                                    <color-input id="textColor" [(ngModel)]="item.textColor" [disabled]="_processing"></color-input>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col many-inputs">
                        <div class="input-container input-container-bg-image">
                            <label trans>Image</label>
                            <div class="image-preview"
                                 *ngIf="getImageSrc(item)"
                                 (click)="!_processing && updateImage(item)"
                                 [matTooltip]="'Change image' | trans">
                                <img [src]="getImageSrc(item)" [alt]="item.imageAlt">
                            </div>
                            <div class="action-buttons">
                                <button type="button" class="action-button" mat-stroked-button color="accent"
                                        (click)="!_processing && updateImage(item)" trans>Select
                                </button>
                                <button type="button" class="action-button delete-button" mat-icon-button
                                        [matTooltip]="'Remove background image' | trans"
                                        *ngIf="getImageSrc(item)"
                                        (click)="!_processing && deleteImage(item)">
                                    <mat-icon svgIcon="delete"></mat-icon>
                                </button>
                            </div>
                        </div>
                        <ng-container *ngIf="getImageSrc(item)">
                            <div class="input-container">
                                <label for="imageAlt" trans>Alt label</label>
                                <input type="text" id="imageAlt" name="imageAlt" [(ngModel)]="item.imageAlt"
                                       [disabled]="_processing">
                            </div>
                            <div class="input-container">
                                <label for="imagePosition" trans>Image position</label>
                                <ng-select id="imagePosition" name="imagePosition"
                                           appearance="outline"
                                           appendTo="body"
                                           [clearable]="false"
                                           [(ngModel)]="item.imagePosition"
                                           [ngModelOptions]="{standalone: true}">
                                    <ng-option [value]="'start'" trans>Start</ng-option>
                                    <ng-option [value]="'end'" trans>End</ng-option>
                                </ng-select>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <button mat-button (click)="advancedOptionsVisible = !advancedOptionsVisible" trans>Advanced settings</button>

    <div class="row" *ngIf="advancedOptionsVisible">
        <div class="col col-grow many-inputs">
            <div class="input-container">
                <label for="itemHoverOverlayColor" trans>Item hover overlay color</label>
                <color-input id="itemHoverOverlayColor"
                             [(ngModel)]="data.widget.itemHoverOverlayColor"
                             [disabled]="_processing"
                ></color-input>
            </div>
            <div class="input-container">
                <label for="linkIndicatorIconColor" trans>Link indicator icon color</label>
                <color-input id="linkIndicatorIconColor"
                             [(ngModel)]="data.widget.linkIndicatorIconColor"
                             [disabled]="_processing"
                ></color-input>
            </div>
        </div>
        <div class="col col-grow many-inputs">
            <div class="input-container">
                <label for="gridGap" trans>Grid gap</label>
                <input type="text" id="gridGap" name="gridGap"
                       [(ngModel)]="data.widget.gridGap"
                       [disabled]="_processing"
                       placeholder="0, .5rem, 10px,...">
            </div>
            <div class="input-container">
                <label for="linkIndicatorBackgroundColor" trans>Link indicator background color</label>
                <color-input id="linkIndicatorBackgroundColor"
                             [(ngModel)]="data.widget.linkIndicatorBackgroundColor"
                             [disabled]="_processing"
                ></color-input>
            </div>
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions>
    <button (click)="addItem()" mat-raised-button class="add-button" [disabled]="loading" trans>Add block</button>
    <button (click)="close()" type="button" mat-button trans>Cancel</button>
    <button (click)="submit()" mat-raised-button color="accent" [disabled]="loading" trans>Apply</button>
</mat-dialog-actions>
