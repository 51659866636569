export class CardCarouselWidget {
    public cards: CardCarouselWidgetCard[] = [];
    public titleColor?: string;
    public subtitleColor?: string;
    public textColor?: string;
    public cardInactiveOverlayColor?: string;
    public mediaPlayButtonColor?: string;
    public controlsColor?: string;
}

export class CardCarouselWidgetCard {
    public enabled = true;
    public urlEnabled = false;
    public url?: string;
    public urlTargetNewTab?: boolean;
    public title?: string;
    public subtitle?: string;
    public text = '';
    public mediaType?: 'image' | 'video' | '' = '';
    public imageSrc?: string;
    public imageAlt?: string;
    public videoSrc?: string;
    public videoUrl?: string;
    public buttonEnabled = false;
    public buttonLabel?: string;
    public buttonUrl?: string;
    public buttonTargetNewTab?: boolean;
}
