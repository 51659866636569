import {Injectable} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog/dialog-ref';
import {Modal} from '@common/core/ui/dialogs/modal.service';
import {
    ContactPersonContainerWidgetEditorModalComponentData,
    ContactPersonsContainerWidgetEditorModalComponent
} from './contact-persons-container-widget-editor-modal/contact-persons-container-widget-editor-modal.component';

@Injectable({
    providedIn: 'root',
})
export class ContactPersonsContainerWidgetEditor {
    public modalRef: MatDialogRef<ContactPersonsContainerWidgetEditorModalComponent>;

    constructor(
        public modal: Modal
    ) {
    }

    open(
        currentValue: ContactPersonContainerWidgetEditorModalComponentData
    ): MatDialogRef<ContactPersonsContainerWidgetEditorModalComponent> {
        this.modalRef = this.modal.open(ContactPersonsContainerWidgetEditorModalComponent, currentValue);
        return this.modalRef;
    }

    close() {
        this.modalRef?.close();
    }
}
