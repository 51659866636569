import {ArchitectElement} from '../../architect-element';
import {Injector} from '@angular/core';
import {CurrentUser} from '@common/auth/current-user';
import {I18nKeyControl} from './i18n-key-control';
import {i18nEditActionCreator} from './i18n-edit-action';

export class I18nWidgetEl extends ArchitectElement {
    name = 'Internationalization (i18n)';
    allowedContent = [];
    specificity = 5;
    hiddenElement = true;
    blocksContent = true;
    controls = [
        new I18nKeyControl(this.injector),
    ];

    constructor(protected injector: Injector) {
        super(injector);

        const currentUser = injector.get(CurrentUser);

        if (currentUser.hasPermission('templates.update_i18n')) {
            this.editActions.push(i18nEditActionCreator(this));
        } else {
            this.hiddenElement = true;
        }
    }

    matcher(node: HTMLElement) {
        return node.closest('[data-esb-i18n]') as HTMLElement;
    }
}
