import {EditableProp} from './architect-element-editable-props';
import {BuilderSidebarPanel} from '../sidebar/builder-sidebar-panel';

/**
 * Default properties of architect elements
 */
export const ELEMENT_DEFAULTS = {
    name: 'Generic',
    canEdit: [
        EditableProp.Padding,
        EditableProp.Margin,
        EditableProp.Border,
        EditableProp.Text,
        EditableProp.Attributes,
        EditableProp.Shadow,
        EditableProp.Background,
    ],
    specificity: 0,
    blocksContent: false,
    hiddenElement: false,
    canDrag: true,
    canCopy: true,
    canDelete: true,
    controls: [],
    resizable: true,
    contextMenu: true,
    contentCategories: ['flow'],
    allowedContent: ['flow'],
    allowedEls: [],
    hiddenClasses: [],
    defaultInspectorPanel: BuilderSidebarPanel.Inspector,
};
