import {ArchitectElement, ArchitectElementAction} from './architect-element';
import {flattenArray} from '@common/core/utils/flatten-array';

/**
 * Returns the main action of an element. The main action is the first action in the editActions array.
 * If no action exists, undefined is returned.
 * @param element ArchitectElement
 * @param node HTMLElement
 */
export function getMainAction(element?: ArchitectElement, node?: HTMLElement): ArchitectElementAction | undefined {
    if (element == null || node == null) {
        return undefined;
    }

    const enabledActions = element.getEnabledActions != null ? element.getEnabledActions(node) : [];

    const firstEnabledAction = enabledActions[0];
    if (firstEnabledAction != null) {
        return firstEnabledAction;
    }

    return undefined;
}

/**
 * From a list of actions, return only unique and valid actions
 * @param actions actions to process
 * @return valid list of actions
 */
export function getValidActions(actions: ArchitectElementAction[]): ArchitectElementAction[] {
    const preventedActionIds = new Set(flattenArray(actions.map(action => action.preventActionIds ?? [])));

    const allUniqueActionsResult = actions.reduce((result, action) => {
        const actionId = action.id;
        if (actionId == null) {
            result.actions.push(action);
        } else {
            if (preventedActionIds.has(actionId) || result.addedActionIds.has(actionId)) {
                // skip, it is already in the actions list
            } else {
                result.actions.push(action);
                result.addedActionIds.add(actionId);
            }
        }
        return result;
    }, {
        actions: [],
        addedActionIds: new Set<string>()
    });

    return allUniqueActionsResult.actions;
}
