<div>
    <div class="modal-header">
        <h2 class="modal-title" mat-dialog-title trans>Contact Person Container</h2>
        <button type="button" (click)="close()" class="close-button no-style" tabindex="-1">
            <mat-icon svgIcon="close"></mat-icon>
        </button>
    </div>
</div>

<mat-dialog-content>
    <div class="input-container">
        <label for="elementsPerRow" trans>Elements per row</label>
        <input type="number" id="elementsPerRow" name="elementsPerRow" [(ngModel)]="data.elementsPerRow">
    </div>
</mat-dialog-content>

<mat-dialog-actions>
    <button (click)="close()" type="button" mat-button trans>Cancel</button>
    <button (click)="submit()" mat-raised-button color="accent" trans>Apply</button>
</mat-dialog-actions>
