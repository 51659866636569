import {ArchitectElement} from '../../architect-element';
import {InlineTextEditor} from '../../../overlays/inline-text-editor/inline-text-editor.service';
import {randomString} from '@common/core/utils/random-string';
import {MutationsService} from '../../../mutations/mutations.service';
import {InsertNode} from '../../../mutations/dom/insert-node';

const accordionItemTemplate = `<div class="accordeon-item mt-3">
    <div class="accordeon-header" data-toggle="collapse" data-target="#acc-{{id}}" aria-expanded="true" aria-controls="">Akkordion Header</div>
    <div id="acc-{{id}}" class="collapse pt-3 pb-2 accordeon-body">
        <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</p>
        <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.</p>
    </div>
</div>
`;

function getRandomAccordionId(): string {
    return randomString(10);
}

export class AccordionWidgetItemEl extends ArchitectElement {
    name = 'Accordion Item';
    contentCategories = ['accordeon-item'];
    allowedContent = ['accordeon-body'];
    category = null;
    canDrag = false;
    canCopy = false;
    specificity = 6;
    hiddenClasses = ['accordeon-item', 'mt-3'];
    editActions = [{
        name: 'Edit Header',
        onClick: (node: HTMLElement) => {
            const headerNode = node.querySelector('.accordeon-header') as HTMLElement;
            this.inject(InlineTextEditor).open(headerNode);
        }
    }, {
        name: 'Add Element above',
        onClick: (node: HTMLElement) => {
            const newElement = AccordionWidgetItemEl.buildTemplate();

            this.inject(MutationsService).execute(
                new InsertNode(newElement, this.getIndexOfElement(node) ?? 0, node.parentElement.dataset.arId)
            );
        }
    }, {
        name: 'Add Element below',
        onClick: (node: HTMLElement) => {
            const newElement = AccordionWidgetItemEl.buildTemplate();

            this.inject(MutationsService).execute(
                new InsertNode(newElement, (this.getIndexOfElement(node) ?? 0) + 1, node.parentElement.dataset.arId)
            );
        }
    }];

    public static buildTemplateAsString(): string {
        return accordionItemTemplate.replace(/{{id}}/g, getRandomAccordionId());
    }

    public static buildTemplate(): HTMLElement {
        const htmlString = this.buildTemplateAsString();
        return new DOMParser().parseFromString(htmlString, 'text/html').querySelector('.accordeon-item') as HTMLElement;
    }

    private getIndexOfElement(element: HTMLElement): number | undefined {
        for (let i = 0; i < element.parentElement.childNodes.length; i++) {
            if (element.parentElement.childNodes[i] === element) {
                return i;
            }
        }

        return undefined;
    }

    matcher(node: HTMLElement) {
        return node.closest('.accordeon-item') as HTMLElement;
    }
}
