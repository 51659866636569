import {CardCarouselWidget, CardCarouselWidgetCard} from './card-carousel-widget.model';
import {isNotBlank} from '../../../../shared/util/string-util';

export function extractCardCarouselWidget(markupEl: HTMLElement): CardCarouselWidget {
    const widget = new CardCarouselWidget();

    const configEl = markupEl.querySelector('.sb-card-carousel-widget__config') as HTMLElement;
    if (configEl != null) {
        widget.titleColor = configEl.dataset.titleColor;
        widget.subtitleColor = configEl.dataset.subtitleColor;
        widget.textColor = configEl.dataset.textColor;
        widget.cardInactiveOverlayColor = configEl.dataset.cardInactiveOverlayColor;
        widget.controlsColor = configEl.dataset.controlsColor;
        widget.mediaPlayButtonColor = configEl.dataset.mediaPlayButtonColor;
    }

    const cardMarkupEls = markupEl.querySelectorAll('.sb-card-carousel-widget__card');
    for (let i = 0; i < cardMarkupEls.length; i++) {
        const cardMarkupEl = cardMarkupEls.item(i);
        const card = extractCardCarouselWidgetCard(cardMarkupEl as HTMLElement);
        widget.cards.push(card);
    }
    return widget;
}

function extractCardCarouselWidgetCard(cardMarkupEl: HTMLElement): CardCarouselWidgetCard {
    const card = new CardCarouselWidgetCard();

    const titleEl = cardMarkupEl.querySelector('.sb-card-carousel-widget__card__title') as HTMLElement;
    const subtitleEl = cardMarkupEl.querySelector('.sb-card-carousel-widget__card__subtitle') as HTMLElement;
    const textEl = cardMarkupEl.querySelector('.sb-card-carousel-widget__card__text') as HTMLElement;
    const imageEl = cardMarkupEl.querySelector('.sb-card-carousel-widget__card__image') as HTMLElement;
    const videoEl = cardMarkupEl.querySelector('.sb-card-carousel-widget__card__video') as HTMLElement;
    const buttonEl = cardMarkupEl.querySelector('.sb-card-carousel-widget__card__button') as HTMLElement;

    card.enabled = cardMarkupEl.dataset.enabled !== 'false';
    card.mediaType = cardMarkupEl.dataset.mediaType as 'video' | 'image' | undefined;
    if (isNotBlank(cardMarkupEl.dataset.url)) {
        card.urlEnabled = true;
        card.url = cardMarkupEl.dataset.url;
        card.urlTargetNewTab = cardMarkupEl.dataset.urlTargetNewTab === 'true';
    }

    if (titleEl != null) {
        card.title = titleEl?.textContent;
    }

    if (subtitleEl != null) {
        card.subtitle = subtitleEl?.textContent;
    }

    if (textEl != null) {
        card.text = textEl?.innerHTML;
    }

    if (imageEl != null) {
        card.imageSrc = imageEl.dataset.src;
        card.imageAlt = imageEl.dataset.alt;
    }

    if (videoEl != null) {
        card.videoUrl = videoEl.dataset.url;
        card.videoSrc = videoEl.dataset.src;
    }

    if (buttonEl != null) {
        card.buttonEnabled = true;
        card.buttonLabel = buttonEl.dataset.label;
        card.buttonUrl = buttonEl.dataset.url;
        card.buttonTargetNewTab = buttonEl.dataset.targetNewTab === 'true';
    }

    return card;
}
