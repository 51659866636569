import {I18nWidgetService} from './i18n-widget-editor.service';
import {ArchitectElementAction, ArchitectElementActionCreator} from '../../architect-element';
import {ArchitectTextEl} from '../../architect-text-element';

export const i18nEditActionCreator: ArchitectElementActionCreator = (archEl: ArchitectTextEl): ArchitectElementAction => ({
    id: 'action.i18n.edit',
    name: 'Edit i18n',
    onClick: async (node: HTMLElement) => {
        const i18nKey = node.getAttribute('data-esb-i18n') ?? '';
        await archEl.inject(I18nWidgetService)
            .open(node, i18nKey);
    },
    isEnabled: (node: HTMLElement): boolean => {
        return node.getAttribute('data-esb-i18n') != null;
    },
    preventActionIds: ['action.text.edit'],
});
