import {ContactPersonsModel} from './contact-persons-widget.model';

export function extractCardCarouselWidgetCard(cardMarkupEl: HTMLElement): ContactPersonsModel {
    const result = new ContactPersonsModel();

    const imageEl = cardMarkupEl.querySelector('img') as HTMLImageElement;
    const textEl = cardMarkupEl.querySelector('p') as HTMLParagraphElement;

    result.name = textEl.querySelector('strong').textContent;
    result.title = parseTitle(textEl.innerHTML);

    result.primaryImageSrc = imageEl.dataset.primary;
    result.hoverImageSrc = imageEl.dataset.switch;
    if (result.hoverImageSrc === '') {
        result.hoverImageSrc = undefined;
    }
    result.imageAlt = imageEl.alt;

    return result;
}

function parseTitle(content: string): string {
    return content.replace(/<strong.*>.*<\/strong>/, '')
        .replace(/\\n/g, '')
        .replace(/<br.*?>/g, '\n')
        .split('\n')
        .map(line => line.trim())
        .filter(line => line !== '')
        .join('\n');
}
