export interface ContentBlockMatch {
    key: string;
    startIndex: number;
    endIndex: number;
    contentStartIndex: number;
    contentEndIndex: number;
}

/**
 * Inspects the content blocks of a content.
 *
 * This class has a corresponding file in the backend as well: \App\Services\ContentBlocks\ContentBlockInspector
 * Important: Keep them in sync!
 */
export class ContentBlockInspector {

    public static getOuterBlocks(content: string, activeBlockKeys: string[]): ContentBlockMatch[] {

        const re = /<!--(start|end)-data-block='(.+?)'-->/g;
        const matches = [];
        let execMatch;
        // tslint:disable-next-line:no-conditional-assignment
        while ((execMatch = re.exec(content)) != null) {
            matches.push(execMatch);
        }

        const blockMatches: ContentBlockMatch[] = [];
        matches.forEach(match => {
            const key = match[2];
            const type = match[1];
            const matchStartIndex = match.index;
            const matchEndIndex = match.index + match[0].length;

            if (type === 'start') {
                blockMatches.push({
                    key,
                    startIndex: matchStartIndex,
                    endIndex: -1,
                    contentStartIndex: matchEndIndex,
                    contentEndIndex: -1
                });
            } else if (type === 'end') {
                const startBlockMatch = blockMatches.find(blockMatch => blockMatch.key === key && blockMatch.endIndex === -1);
                if (startBlockMatch != null) {
                    startBlockMatch.endIndex = matchEndIndex;
                    startBlockMatch.contentEndIndex = matchStartIndex;
                }
            }
        });

        const validBlockMatches = blockMatches.filter(blockMatch => {
            return blockMatch.endIndex !== -1;
        });

        const activeBlockMatches = validBlockMatches.filter(blockMatch => {
            return activeBlockKeys.includes(blockMatch.key);
        });

        const outerBlockMatches = [];
        let currentBlockMatch: ContentBlockMatch;
        activeBlockMatches.forEach(blockMatch => {
            if (currentBlockMatch == null) {
                // first block
                currentBlockMatch = blockMatch;
                outerBlockMatches.push(blockMatch);
            } else {
                if (currentBlockMatch.endIndex <= blockMatch.startIndex) {
                    // next block starts the current block ends -> new outer block -> take
                    currentBlockMatch = blockMatch;
                    outerBlockMatches.push(blockMatch);
                } else {
                    // next block starts the current block ends -> within outer block -> skip
                }
            }
        });

        return outerBlockMatches;
    }

    public static getOuterBlockKeys(content: string, activeBlockKeys: string[]): string[] {
        const outerBlocks = ContentBlockInspector.getOuterBlocks(content, activeBlockKeys);

        const outerBlockKeys = outerBlocks.map((blockMatch) => {
            return blockMatch.key;
        });

        const uniqueOuterBlockKeys = [...new Set(outerBlockKeys)];

        return uniqueOuterBlockKeys;
    }

}
