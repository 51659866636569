import {ArchitectElement} from '../../architect-element';
import {MutationsService} from '../../../mutations/mutations.service';
import {template as singleContactPersonTemplate} from './contact-persons-widget';
import {InsertNode} from '../../../mutations/dom/insert-node';
import {ModifyAttributes} from '../../../mutations/attributes/modify-attributes';
import {
    ContactPersonContainerWidgetEditorModalComponentData
} from './contact-persons-container-widget-editor-modal/contact-persons-container-widget-editor-modal.component';
import {ContactPersonsContainerWidgetEditor} from './contact-persons-container-widget-editor.service';

const template = `<div class="contact-person-container" style="display: grid; grid-template-columns: repeat(4, 1fr);" data-elements="4">
${singleContactPersonTemplate}
${singleContactPersonTemplate}
${singleContactPersonTemplate}
</div>
`;

export class ContactPersonsContainerWidgetEl extends ArchitectElement {
    name = 'Contact Persons';
    contentCategories = ['flow'];
    html = template;
    allowedContent = ['contact-person'];
    category = 'components';
    icon = 'account-box';
    specificity = 5;
    hiddenClasses = ['contact-person-container'];
    editActions = [
        {
            preventActionIds: ['action.text.edit'],
            name: 'Add contact person',
            onClick: (node: HTMLElement) => {
                const newElement =  new DOMParser().parseFromString(singleContactPersonTemplate, 'text/html').querySelector('.contact-person') as HTMLElement;

                this.inject(MutationsService).execute(
                    new InsertNode(newElement, node.children.length, node.dataset.arId)
                );
            },
        },
        {
            preventActionIds: ['action.layout.edit'],
            name: 'Change style',
            onClick: (node: HTMLElement) => {
                const elementsPerRow: number = parseInt(node.dataset.elements);

                this.inject(ContactPersonsContainerWidgetEditor)
                    .open({elementsPerRow})
                    .afterClosed()
                    .subscribe((data: ContactPersonContainerWidgetEditorModalComponentData) => {
                        this.inject(MutationsService).execute(
                            new ModifyAttributes(node, {
                                style: `display: grid; grid-template-columns: repeat(${data.elementsPerRow}, 1fr)`,
                                'data-elements': `${data.elementsPerRow}`
                            })
                        );
                    });
            },
        },
    ];

    matcher(node: HTMLElement) {
        return node.closest('.contact-person-container') as HTMLElement;
    }
}
