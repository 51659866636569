import {Injector, ProviderToken} from '@angular/core';
import {getValidActions} from './element.util';
import {BuilderSidebarPanel} from '../sidebar/builder-sidebar-panel';
import {ELEMENT_DEFAULTS} from './element-defaults';
import {EditableProp} from './architect-element-editable-props';

export interface ArchitectElControlConfig {
    /**
     * Label of the control
     */
    label: string;

    /**
     * Type of the control
     */
    type: ElControlType;

    /**
     * Default value of the control
     */
    defaultValue: string | ((node: HTMLElement) => string);

    /**
     * Type of the input field.
     *
     * Only used for input fields type.
     */
    inputType?: 'text' | 'number';

    /**
     * Options for select fields
     *
     * Only used for select fields type.
     */
    options?: { key: string; value: string }[];

    /**
     * Function that is called when the value of the control changes
     * @param node The node that the control is attached to
     * @param value The new value of the control
     */
    onChange?: (node: HTMLElement, value: string) => void;

    /**
     * Determines if the control is enabled for the given node. If not provided, the control is always enabled.
     * @param node The node to check
     */
    isEnabled?: (node: HTMLElement) => boolean;
}

export class ArchitectElControl implements Partial<ArchitectElControlConfig> {
    label: string;

    /**
     * Injects a provider by the given token
     * @param token token of the provider
     */
    public inject<T>(token: ProviderToken<T>): T {
        return this.injector.get(token);
    }

    constructor(
        protected injector: Injector,
        config: ArchitectElControlConfig
    ) {
        Object.entries(config).forEach(([key, value]) => {
            this[key] = value;
        });
    }
}

export enum ElControlType {
    /**
     * Select field
     */
    Select = 'select',

    /**
     * Input field
     */
    Input = 'input',
}

export interface ArchitectElementAction {
    /**
     * Identifier of an action. This is used, to prevent to show an action multiple times.
     */
    id?: string;

    /**
     * The name of the action. This is displayed to the user.
     */
    name: string;

    /**
     * The function to execute when the action is clicked
     * @param node The node that the action was clicked on
     */
    onClick: (node: HTMLElement) => void;

    /**
     * Determines if the action is enabled for the given node. If not provided, the action is always enabled.
     * @param node The node to check
     */
    isEnabled?: (node: HTMLElement) => boolean;

    /**
     * List of action ids that will be prevented to be enabled, if this action is enabled. This is useful if an action is "overriding" another action.
     */
    preventActionIds?: string[];
}

/**
 * Function that creates an action for an architect element
 */
export type ArchitectElementActionCreator = (control: ArchitectElement) => ArchitectElementAction;

/**
 * Base class for all architect elements
 */
export abstract class ArchitectElement {

    abstract name: string;

    /**
     * Icon that is shown in the panel.
     */
    icon?: string;

    category?: string;

    /**
     * HTML Content that is used, when the element is dropped/inserted into the builder.
     */
    html?: string | (() => string);
    css?: string; // do not use this, since this triggers a save-action. put the css instead in edsitebuilder-ui-elements

    /**
     * Defines a list of css classes that won't be visible for editing
     */
    hiddenClasses?: string[] = [...ELEMENT_DEFAULTS.hiddenClasses];

    /**
     * Defines the priority of the action. The higher the number, as higher the priority.
     */
    specificity = ELEMENT_DEFAULTS.specificity;

    /**
     * If set to the true, all elements below this element will be blocked.
     * Such architect elements should use node.closest(.) in the matcher function, so that the element matches, if a child node is selected.
     */
    blocksContent = ELEMENT_DEFAULTS.blocksContent;

    hiddenElement = ELEMENT_DEFAULTS.hiddenElement;

    editActions: ArchitectElementAction[] = [];

    canEdit: EditableProp[] = [
        EditableProp.Padding,
        EditableProp.Margin,
        EditableProp.Border,
        EditableProp.Attributes,
        EditableProp.Shadow,
        EditableProp.Background,
    ];
    defaultInspectorPanel = BuilderSidebarPanel.Inspector;
    canDrag = ELEMENT_DEFAULTS.canDrag;
    canCopy = ELEMENT_DEFAULTS.canCopy;
    canDelete = ELEMENT_DEFAULTS.canDelete;
    controls: any[] = [];
    resizable = ELEMENT_DEFAULTS.resizable;
    contextMenu = ELEMENT_DEFAULTS.contextMenu;
    contentCategories = [...ELEMENT_DEFAULTS.contentCategories];
    allowedContent: string[] = [...ELEMENT_DEFAULTS.allowedContent];
    allowedEls: typeof ArchitectElement[] = [...ELEMENT_DEFAULTS.allowedEls];

    /**
     * Returns the enabled actions for the given node
     * @param node The node to check
     *
     * The method might be undefined, because dynamic builder/elements/*.html elements can not extend this class, but act as such
     */
    getEnabledActions?: ((node: HTMLElement) => ArchitectElementAction[]);

    /**
     * Checks, if the architect element is active for the given node.
     * If it is for the node itself, the 'true' is returned.
     * If it matches, because of another element (e.g. some parent), the matching parent element is returned.
     * If it does not match, 'false' is returned
     * @param node node to check for a match
     */
    abstract matcher(node: HTMLElement): boolean | HTMLElement;

    /**
     * Returns a provider by the given token. This is useful to get a service within an element method.
     * @param token token of the provider
     */
    public inject<T>(token: ProviderToken<T>): T {
        return this.injector.get(token);
    }

    /**
     * Base constructor
     * @param injector Injector
     */
    constructor(protected injector: Injector) {
        this.getEnabledActions = (node: HTMLElement): ArchitectElementAction[] => {
            const allActions = this.editActions
                .filter(action => action.isEnabled == null || action.isEnabled(node));
            const validActions = getValidActions(allActions);
            return validActions;
        };
    }
}
