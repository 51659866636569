import {ArchitectElement} from '../../architect-element';
import {MutationsService} from '../../../mutations/mutations.service';
import {ReplaceNodeContent} from '../../../mutations/dom/replace-node-content';
import {extractBlockGridWidget} from './block-grid-widget-markup-extractor';
import {generateBlockGridWidgetMarkup} from './block-grid-widget-markup-generator';
import {BlockGridWidgetEditor} from './block-grid-widget-editor.service';
import {BlockGridWidget} from './block-grid-widget.model';

const template = `<div class="sb-block-grid-widget">
    <div class="sb-block-grid-widget__item"></div>
</div>`;

export class BlockGridWidgetEl extends ArchitectElement {
    name = 'Block Grid';
    contentCategories = ['flow'];
    html = template;
    allowedContent = [];
    category = 'components';
    icon = 'view-comfy';
    specificity = 5;
    hiddenClasses = ['sb-block-grid-widget'];
    editActions = [
        {
            name: 'Edit',
            onClick: (node: HTMLElement) => {
                const widget = extractBlockGridWidget(node);
                this.inject(BlockGridWidgetEditor)
                    .open(node, widget)
                    .afterClosed()
                    .subscribe((updatedWidget?: BlockGridWidget) => {
                        if (updatedWidget != null) {
                            const newInnerHTML = generateBlockGridWidgetMarkup(updatedWidget);
                            this.inject(MutationsService).execute(
                                new ReplaceNodeContent(node, node.innerHTML, newInnerHTML)
                            );
                        }
                    });
            },
        },
    ];

    matcher(node: HTMLElement) {
        return node.closest('.sb-block-grid-widget') as HTMLElement;
    }
}
