import {ArchitectElControl, ArchitectElement, ElControlType} from '../architect-element';
import {EditableProp} from '../architect-element-editable-props';
import {MutationsService} from '../../mutations/mutations.service';
import {ModifyAttributes} from '../../mutations/attributes/modify-attributes';
import {getEmbeddedVideoUrl, getVideoUrlByUrl} from '../../../shared/video/videos.util';

export class YoutubeVimeoVideoEl extends ArchitectElement {
    name = 'youtube-vimeo video';
    html =
        '<div class="video-youtube-vimeo embed-responsive embed-responsive-16by9"><iframe class="embed-responsive-item" src="https://www.youtube-nocookie.com/embed/sENM2wA_FTg" data-url="https://www.youtube.com/watch?v=sENM2wA_FTg" allow="autoplay; fullscreen" allowfullscreen></iframe></div>';
    contentCategories = ['flow'];
    allowedContent = [];
    category = 'media';
    icon = 'video-library';
    canEdit = [
        EditableProp.Attributes,
    ];
    controls = [
        new ArchitectElControl(this.injector, {
            label: 'Youtube/Vimeo URL',
            type: ElControlType.Input,
            defaultValue(node: HTMLInputElement) {
                const currentSrc = node.querySelector('iframe')?.src ?? '';

                const src = getVideoUrlByUrl(currentSrc) ?? '';

                return src;
            },
            onChange(node: HTMLElement, value: string) {
                const dataUrl = value;

                const src = getEmbeddedVideoUrl(dataUrl) ?? '';

                const iframe = node.querySelector('iframe');
                this.inject(MutationsService).execute(
                    new ModifyAttributes(iframe, {
                        src,
                        'data-url': dataUrl
                    })
                );
            },
        }),
    ];
    hiddenClasses = [
        'video-youtube-vimeo',
        'embed-responsive',
        'embed-responsive-16by9',
        'preview-node',
        'img-responsive',
    ];
    specificity = 1;

    matcher(node: HTMLElement) {
        return node.closest('.video-youtube-vimeo') as HTMLElement;
    }
}
